<template>
	<div class="flex flex-col gap-3 object-parameters__tabs h-full w-1/4 pt-5 pl-5">
		<div v-for="tab in tabs" :key="tab.value" class="object-parameters__tabs__item flex items-center gap-2">
			<div v-if="activeTab === tab.value" class="object-parameters__tabs__item__active rounded-full" />
			<span :class="['cursor-pointer', { active: activeTab === tab.value }]" @click="$emit('onChange', tab.value)">
				{{ tab.label }}
			</span>
		</div>
	</div>
</template>
<script setup>
import sections from '@/enums/newUnits/parameters-sections.js';

import { useI18n } from '@/hooks/useI18n';

const $t = useI18n()

const props = defineProps({
	activeTab: {
		type: String,
		default: '',
		required: true
	}
})

const tabs = [
	{
		label: $t(`new_units.objects.fields.parameters.tabs.${sections.TRIPS}`),
		value: sections.TRIPS
	},
	{
		label: $t(`new_units.objects.fields.parameters.tabs.${sections.FUEL}`),
		value: sections.FUEL
	},
	{
		label: $t(`new_units.objects.fields.parameters.tabs.${sections.ENGINE_HOURS}`),
		value: sections.ENGINE_HOURS
	},
	{
		label: $t(`new_units.objects.fields.parameters.tabs.${sections.VIDEO}`),
		value: sections.VIDEO
	},
	{
		label: $t(`new_units.objects.fields.parameters.tabs.${sections.ADDITIONAL}`),
		value: sections.ADDITIONAL
	},
	{
		label: $t(`new_units.objects.fields.parameters.tabs.${sections.PARAMETERS}`),
		value: sections.PARAMETERS
	},
  {
    label: $t(`new_units.objects.fields.parameters.tabs.${sections.FILTER_DATA}`),
		value: sections.FILTER_DATA
  }
] 
</script>